<template>
    <div>
      <p-o-d-9-2023-report></p-o-d-9-2023-report>
    </div>
  </template>
  
  <script>
  import POD92023Report from "@/components/ecologist/reports/POD-9/POD-9Report-2023";
  export default {
    name: "POD-9-2023View",
    components: { POD92023Report },
  };
  </script>
  
  <style scoped></style>
  